//import { envVariables } from './azureEnvConfig';
let environmentSpecificData = {};
let environmentSpecificDefaults = {
	'dev': { //qa/stg
		//baseApiUrl: 'http://localhost:4000/',
		environment: 'dev',
		contactUsEmail: 'MyAcurianHealthContactUs.sm@ppd.com',
		clientId: 'c1d2e539-4d27-45b9-b10d-163e44036e41',
		tenentURL:
			'https://patientportalb2cdev.b2clogin.com/patientportalb2cdev.onmicrosoft.com',
		signIn: 'B2C_1_signin',
		forgotPassword: 'B2C_1_passwordreset',
		signUp: 'B2C_1_signup', //not used possibly
        signUpSignIn: "B2C_1_susi",
		editProfile: 'B2C_1_edit_profile', //not used possibly
		authorityDomain: 'patientportalb2cdev.b2clogin.com',
		protectedResourcesDomain: 'https://patientportalb2cdev.onmicrosoft.com' //not sure how used but tutorial has as .onmicrosoft.com domain
	},
	'prod': {
		baseApiUrl: 'https://patientportal-be.globalaes-aws.systems/',
		environment: 'prod',
		contactUsEmail: 'MyAcurianHealthContactUs.sm@ppd.com',
		clientId: '7044ecf0-b773-4fca-b7e0-ea5faca689b0',
		tenentURL:
			'https://patientportalprodb2c.b2clogin.com/patientportalprodb2c.onmicrosoft.com',
		signIn: 'B2C_1_signin',
		forgotPassword: 'B2C_1_passwordreset',
		signUp: 'B2C_1_signup', //not used possibly
        signUpSignIn: "B2C_1_susi",
		editProfile: 'B2C_1_edit_profile', //not used possibly
		authorityDomain: 'patientportalprodb2c.b2clogin.com',
		protectedResourcesDomain: 'https://patientportalprodb2c.onmicrosoft.com' //not sure how used but tutorial has as .onmicrosoft.com domain
	}
};
environmentSpecificDefaults.local  = {...environmentSpecificDefaults.dev}; //make copy of obj instead of modifiying orig reference
environmentSpecificDefaults.local.environment = 'dev'; //or local
environmentSpecificDefaults.local.baseApiUrl = 'http://localhost:4000/';

environmentSpecificDefaults.dev  =  {...environmentSpecificDefaults.dev};
environmentSpecificDefaults.dev.environment = 'dev';
environmentSpecificDefaults.dev.baseApiUrl = 'https://dev-patientportal-be.np.globalaes-aws.systems/';

environmentSpecificDefaults.qa  =  {...environmentSpecificDefaults.dev};
environmentSpecificDefaults.qa.environment = 'qa';
environmentSpecificDefaults.qa.baseApiUrl = 'https://qa-patientportal-be.np.globalaes-aws.systems/';

environmentSpecificDefaults.stg =  {...environmentSpecificDefaults.dev};
environmentSpecificDefaults.stg.environment = 'stg';
environmentSpecificDefaults.stg.baseApiUrl = 'https://uat-patientportal-be.np.globalaes-aws.systems/';

//https://patientportalb2cdev.b2clogin.com/patientportalb2cdev.onmicrosoft.com
//https://patientportalprodb2c.b2clogin.com/patientportalprodb2c.onmicrosoft.com
//c1d2e539-4d27-45b9-b10d-163e44036e41
//7044ecf0-b773-4fca-b7e0-ea5faca689b0


// This function changes the api url depending on the url origin
function urlChecker() {
	switch (window.location.origin) {
		case 'http://localhost:5000':
			environmentSpecificData = environmentSpecificDefaults.local; 
			break;
		case 'https://dev-screener-fe.np.globalaes-aws.systems':	
			environmentSpecificData = environmentSpecificDefaults.dev;
			break;
		case 'https://pp-screener-fe.np.globalaes-aws.systems':
			environmentSpecificData = environmentSpecificDefaults.qa;
			break;
		case 'https://uat-screener-fe.np.globalaes-aws.systems':
			environmentSpecificData = environmentSpecificDefaults.stg;
			break;
		case 'https://screener.globalaes-aws.systems':
			environmentSpecificData = environmentSpecificDefaults.prod;
			break;
		case 'https://screener.acurianhealth.com':
			environmentSpecificData = environmentSpecificDefaults.prod
			break;
	}
	sessionStorage.setItem('environmentSpecificData', environmentSpecificData);
	return environmentSpecificData;
}

const envVariables = urlChecker();

//################################# we don't have babel to import files so including all the code above
const { tenentURL, signIn, forgotPassword, signUp, signUpSignIn, editProfile, authorityDomain, clientId, protectedResourcesDomain } =
	envVariables;
    
const b2cPolicies = {
    names: {
        signUpSignIn,
        forgotPassword,
        editProfile,
        signUp,
        signIn
    },
    authorities: {
        signUpSignIn: {
            authority: `${tenentURL}/${signUpSignIn}` //b2c_1_susi
        },
        signIn: {
            authority: `${tenentURL}/${signIn}` //b2c_1_signin
        },
        signUp: {
            authority: `${tenentURL}/${signUp}` //b2c_1_signup
        },
        forgotPassword: {
            authority: `${tenentURL}/${forgotPassword}` //b2c_1_passwordreset
        },
        editProfile: {
            authority: `${tenentURL}/${editProfile}` //b2c_1_edit_profile
        }
    },
    authorityDomain: authorityDomain
}



const msalConfig = {
    auth: {
        clientId: clientId,
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: "/",
        postLogoutRedirectUri: "/",
        navigateToLoginRequestUrl: true,
        navigateToLogoutRequestUrl: true,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case msal.LogLevel.Error:
                        console.error(message);
                        return;
                    case msal.LogLevel.Info:
                        console.info(message);
                        return;
                    case msal.LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case msal.LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

const loginRequest = b2cPolicies.authorities.signIn
const signUpRequest = b2cPolicies.authorities.signUp

const tokenRequest = {
    scopes: ["User.Read", "Mail.Read"],
    forceRefresh: false
};
